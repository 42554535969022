<template>
  <div>
    <el-form :model="params" :rules="contentRules" ref="contentRuleForm">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        @close="handleDialogClose('contentRuleForm')"
        width="640px"
      >
        <div>
          <p class="problem-desc">问题反馈</p>
          <el-form-item prop="content">
            <el-input
              type="textarea"
              placeholder="请详细描述您在使用过程中遇到的问题和需要改进的建议（5-100字以内）"
              v-model="params.content"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <!-- <p class="prompt-desc">请输入您的建议或问题（5-100个字符）</p> -->
          <el-checkbox v-model="checked" @change="changeChecked">附上文章内容截图，标记具体内容帮助我们完善文档</el-checkbox>
          <div class="img-flex">
            <div class="mini-canvas">
              <el-upload
                :action="uploadDocSrc"
                list-type="picture-card"
                :auto-upload="false"
                :limit="5"
                :class="{hide:hideUploadEdit}"
                :on-change="handleEditChange"
                :on-remove="hadleRemove"
                :before-upload="beforeUpload"
                ref="upload"
                name="image"
                :data="upData"
                :on-success="uploadImgSuccess"
                :on-error="uploadImgError"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelCommit('contentRuleForm')">取 消</el-button>
          <el-button type="primary" @click="getCommit('contentRuleForm')">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="imgDialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
// import { getCookieToken } from '../../../utils.js'
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      checked: true,
      dialogImageUrl: '',
      imgDialogVisible: false,
      disabled: false,
      hideUploadEdit: false,
      file: {},
      isLogin: '',
      params: {
        doc_id: this.$route.params.id,
        content: ''
      },
      contentRules: {
        content: [
          {
            required: true,
            message: '请输入您的建议或问题（5-100个字符）',
            trigger: 'change'
          },
          {
            min: 5,
            max: 100,
            message: '请输入您的建议或问题（5-100个字符）',
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    uploadDocSrc() {
      return `${process.env.VUE_APP_API_url}document/feedback/commit`
    },
    upData: function () {
      return this.params
    }
  },
  watch: {
    upData: {
      deep: true,
      handler: function(val) {
        val.doc_id = this.$route.params.id
      }
    }
  },
  methods: {
    // 上传文件之前的回调
    beforeUpload(file) {
      console.log(file)
    },
    // 点击右上角关闭回调
    handleDialogClose(formName) {
      this.$refs[formName].resetFields()
    },
    // 手动提交
    getCommit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let file = this.$refs.upload.uploadFiles
          if (file.length === 0) {
            this.$fetch('commitFeedBack', this.upData)
              .then(() => {
                this.$message.success('提交成功')
              })
              .catch(() => {
                this.$message.error('提交失败')
              })
          } else {
            this.$refs.upload.submit()
          }
          this.dialogVisible = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 手动取消
    cancelCommit(formName) {
      this.dialogVisible = false
      this.$refs[formName].resetFields()
      this.$refs.upload.clearFiles()
    },
    show() {
      this.params.content = ''
      this.dialogVisible = true
    },
    // 文件删除的回调
    handleRemove(file) {
      console.log(file)
      // 实现缩略图模板时删除文件
      let fileList = this.$refs.upload.uploadFiles
      let index = fileList.findIndex((fileItem) => {
        return fileItem.uid === file.uid
      })
      fileList.splice(index, 1)
      this.hideUploadEdit = fileList.length >= 5
    },
    // 预览缩略图回调
    handlePictureCardPreview(file) {
      this.imgDialogVisible = true
      this.dialogImageUrl = file.url
    },
    // 文件状态改变回调
    handleEditChange(file, fileList) {
      const isImage = file.raw.type == 'image/png'
      let isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
        const currIdx = fileList.indexOf(file)
        fileList.splice(currIdx, 1)
        return
      }
      if (!isImage) {
        this.$message.error('上传只能是png格式!')
        const currIdx = fileList.indexOf(file)
        fileList.splice(currIdx, 1)
        return
      }
      this.hideUploadEdit = fileList.length >= 5
      this.file = file
    },
    // 文件列表移除文件回调
    hadleRemove(file, fileList) {
      console.log(file, fileList)
    },
    // 上传成功的回调
    uploadImgSuccess(res) {
      if (res.code !== -1) {
        this.$refs.upload.clearFiles()
        this.$message.success('提交成功')
      } else {
        this.$message.error('提交失败')
      }
    },
    // 上传失败的回调
    uploadImgError(err) {
      this.$message.error(err.error[0].message)
    },
    // 选择框是否选中方法
    changeChecked(item) {
      this.hideUploadEdit = !item
      console.log(item)
      if (!item) {
        this.$refs.upload.clearFiles()
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
/deep/ .el-dialog__title {
  font-size: 18px;
  color: @hoversColor;
  font-weight: 550;
}
/deep/ .el-dialog__header {
  padding: 24px;
  .el-icon-close:before {
    font-size: 16px;
    color: @hoversColor;
  }
}
/deep/ .el-dialog__body {
  padding: 0 24px 22px;
  .img-flex {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    .mini-canvas {
      // width: 112px;
      // height: 112px;
      // background: rgba(0, 0, 0, 0.45);
      // border-radius: 4px;
      margin: 12px 0 0 0;
      .el-upload-list__item {
        width: 105.6px;
        height: 105.6px;
        margin: 0 16px 8px 0;
        div {
          width: 105.6px;
          height: 105.6px;
          img {
            object-fit: cover;
          }
        }
      }
      .el-upload--picture-card {
        // margin-left: 16px;
        width: 105.6px;
        height: 105.6px;
        line-height: 105.6px;
        background: @hoverGrayColor;
        border-radius: 4px;
        border: 1px solid #dde0e3;
      }
      .hide .el-upload--picture-card {
        display: none;
      }
    }
  }
  .problem-desc {
    font-weight: 400;
    color: @hoversColor;
    margin-bottom: 12px;
  }
  .prompt-desc {
    margin: 8px 0 6px;
    color: @fontColor;
    font-size: 12px;
    font-weight: 400;
  }
  // 修改多选框样式
  .border {
    border-color: @mainColor;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: @mainColor;
    .border;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: @hoversColor;
  }
  .el-checkbox__input.is-focus .el-checkbox__inner {
    .border;
  }
  .el-checkbox__inner:hover {
    .border;
  }
}
/deep/ .el-textarea__inner {
  font-size: 14px;
  font-weight: 400;
  color: #9aa3ac;
  min-height: 80px !important;
}
/deep/ .el-dialog__footer {
  .bgcolor {
    background-color: @mainColor;
  }
  .el-button {
    width: 76px;
    height: 36px;
    border-radius: 18px;
    line-height: 0;
    font-weight: 400;
  }
  .el-button--default {
    border: 1px solid #9aa3ac;
    color: @hoversColor;
    &:hover {
      background-color: @whiteBcgColor;
    }
  }
  .el-button--primary {
    .bgcolor;
    &:hover {
      .bgcolor;
      border-color: @mainColor;
    }
    &:active {
      .bgcolor;
    }
  }
}
</style>
