<template>
  <div class="header purple_bcg-color" id="header">
    <div class="c-width header-con">
      <div class="menu">
        <a class="logo" href="/">
          <img
            src="../../../assets/images/logo@2x.png"
            width="59"
            height="19"
            alt
          />
        </a>
        <div class="vertical"></div>
        <el-menu
          class="purple_bcg-color"
          :default-active="$route.path"
          router
          mode="horizontal"
          active-text-color="#1e89e4"
        >
          <el-menu-item index="/index">首页</el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              产品服务
              <i class="iconfont white_down-color">&#xe778;</i>
              <!-- <i class="arrow-down"></i> -->
            </template>
            <el-menu-item index="/live">直播服务</el-menu-item>
            <el-menu-item index="/vod">点播服务</el-menu-item>
            <el-menu-item index="/interactive">音视频互动服务</el-menu-item>
            <el-menu-item index="/document">文档白板服务</el-menu-item>
            <el-menu-item index="/chat">聊天服务</el-menu-item>
            <el-menu-item index="/form">表单服务</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              解决方案
              <i class="iconfont white_down-color">&#xe778;</i>
              <!-- <i class="arrow-down"></i> -->
            </template>
            <el-menu-item index="/enjoy">
              轻享直播定制解决方案
              <i class="icon-new">new</i>
            </el-menu-item>
            <el-menu-item index="/live-answer">直播答题</el-menu-item>
            <el-menu-item index="/live-mini-program">直播小程序</el-menu-item>
            <el-menu-item :index="domain">微吼·云会议</el-menu-item>
          </el-submenu>
          <el-submenu
            :class="{ 'document-center': isDocumentCenter }"
            index="4"
          >
            <template slot="title">
              文档中心
              <i class="iconfont white_down-color">&#xe778;</i>
            </template>
            <el-menu-item index="/document/document/index">全部</el-menu-item>
            <el-submenu index="4-1">
              <template slot="title">服务</template>
              <el-menu-item index="/docs/show/2" @click="JumpService('2')"
                >直播服务</el-menu-item
              >
              <el-menu-item index="/docs/show/181" @click="JumpService('181')"
                >点播服务</el-menu-item
              >
              <el-menu-item index="/docs/show/453" @click="JumpService('453')"
                >音视频互动服务</el-menu-item
              >
              <el-menu-item index="/docs/show/560" @click="JumpService('560')"
                >文档白板服务</el-menu-item
              >
              <el-menu-item index="/docs/show/338" @click="JumpService('338')"
                >聊天服务</el-menu-item
              >
              <el-menu-item index="/docs/show/272" @click="JumpService('272')"
                >表单服务</el-menu-item
              >
              <el-menu-item index="/docs/show/348" @click="JumpService('348')"
                >推送通知服务</el-menu-item
              >
              <el-menu-item index="/docs/show/759" @click="JumpService('759')"
                >数据服务</el-menu-item
              >
            </el-submenu>
            <el-submenu index="4-2">
              <template slot="title">场景方案</template>
              <el-menu-item index="/docs/show/555" @click="JumpService('555')"
                >互动直播Demo</el-menu-item
              >
              <el-menu-item index="/docs/show/817" @click="JumpService('817')"
                >直播带货实时数据指标</el-menu-item
              >
            </el-submenu>
          </el-submenu>
        </el-menu>
      </div>
      <div class="btn-group">
        <a :href="consoleLoginUrl" target="_blank" v-if="!isLogin">
          <el-button type="default" class="button">登录</el-button>
        </a>
        <a :href="consoleLoginUrl" target="_blank" v-if="isLogin">
          <el-button type="default" class="button">控制台</el-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getCookieToken } from '../../../utils.js'
export default {
  props: {
    isDocumentCenter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      docHref: `${window.urlConfig.docUrl}document/document/index`,
      domain: '/client-download',
      consoleLoginUrl: '',
      isLogin: false,
      isJoin: true,
      docId: ''
    }
  },
  created() {
    this.isLogin = getCookieToken()
    if (this.isLogin) {
      this.consoleLoginUrl = `${window.urlConfig.consoleUrl}home`
    } else {
      this.consoleLoginUrl = `${window.urlConfig.consoleUrl}loginLayout/login`
    }
  },
  mounted() {},
  methods: {
    JumpService(item) {
      this.$emit('selectDocId', item)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';

.header-con {
  color: #333;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .menu {
    display: flex;
    align-items: center;
    .logo {
      margin-right: 21px;
      margin-top: 5px;
    }
    .vertical {
      width: 2px;
      height: 16px;
      background: #d8d8d8;
    }
  }

  .headerHeight {
    color: #333;
    height: 54px !important;
  }
  .menuBgColor {
    background: @purpleBcgColor;
  }

  // .menuBorder {
  //   color: @mainColor;
  //   border-bottom: 4px solid @mainColor!important;
  // }

  /deep/ .el-menu-item {
    color: @whiteBcgColor !important;
    font-size: 16px;
    line-height: 54px;
    .headerHeight;
    &.is-active {
      background: @purpleBcgColor !important;
      border-bottom-color: @purpleBcgColor !important;
    }
    &:hover {
      // .menuBorder;
      .menuBgColor;
    }
  }

  /deep/ .el-submenu {
    .el-submenu__title {
      line-height: 54px;
      font-size: 16px;
      color: @whiteBcgColor !important;
      .headerHeight;
      &:hover {
        // border-bottom: 4px solid @mainColor!important;
        .menuBgColor;
      }
      i {
        color: @whiteBcgColor;
      }
    }

    &:hover {
      .el-submenu__title {
        // .menuBorder;
      }
      // .arrow-down {
      //   background-position: -1px -3px;
      // }
    }
    &.is-active {
      .el-submenu__title {
        color: @mainColor;
      }
      // .arrow-down {
      //   background-position: -1px -3px;
      // }
    }
  }

  // .arrow-down {
  //   display: inline-block;
  //   width: 8px;
  //   height: 5px;
  //   background: url('../../../assets/images/nav_select.png') no-repeat -27px -3px;
  //   margin-left: 5px;
  //   vertical-align: middle;
  // }

  /deep/ .el-button {
    padding: 5px 20px;
    border-radius: 4px;
  }
}
</style>
