<template>
  <div>
    <ul class="backToTop">
      <li>
        <a
          href="https://vhall.s4.udesk.cn/im_client/?web_plugin_id=15038"
          target="_blank"
          @mouseenter="enterimgSrc('online')"
          @mouseleave="leaveImgsrc('online')"
        >
          <div class="icon-desc">
            <img :src="imgSrc" />
            <span>在线</span>
          </div>
          <div class="line"></div>
          <p>在线咨询</p>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @mouseenter="enterimgSrc('service')"
          @mouseleave="leaveImgsrc('service')"
        >
          <div class="icon-desc">
            <img :src="serviceImgSrc" />
            <span>客服</span>
          </div>
          <div class="line"></div>
          <p class="relate">400-888-9970</p>
        </a>
      </li>
      <li v-show="isShowReturn">
        <a href="#header">
          <i class="iconfont iconup"></i>
          <div class="line"></div>
          <p>回到顶部</p>
        </a>
      </li>
    </ul>
    <div class="docBox cursor" v-show="isShow" @click="docFeedBackDialog">
      <div>
        <i class="iconfont iconicon-wendangfankui"></i>
        <span>文档</span>
        <span>反馈</span>
      </div>
    </div>
    <div>
      <Dialog :title="title" ref="openDialog"></Dialog>
    </div>
  </div>
</template>

<script>
import Dialog from './feedBackDialog'

export default {
  components: { Dialog },
  data() {
    return {
      isShowReturn: false,
      imgSrc: require('../../../assets/images/doc/icon-online-default@2x.png'),
      serviceImgSrc: require('../../../assets/images/doc/icon-customerservic@2x.png'),
      isShow: false,
      title: '提交反馈建议'
    }
  },
  mounted() {
    if (this.$route.name === 'docs/show') {
      this.isShow = true
    } else {
      this.isShow = false
    }
    window.addEventListener(
      'scroll',
      () => {
        if (document.documentElement.scrollTop > 1000) {
          this.isShowReturn = true
        } else {
          this.isShowReturn = false
        }
      },
      false
    )
  },
  methods: {
    enterimgSrc(value) {
      if (value === 'online') {
        this.imgSrc = require('../../../assets/images/doc/icon-online-hover@2x.png')
      } else {
        this.serviceImgSrc = require('../../../assets/images/doc/icon-customer service-hover@2x.png')
      }
    },
    leaveImgsrc(value) {
      if (value === 'online') {
        this.imgSrc = require('../../../assets/images/doc/icon-online-default@2x.png')
      } else {
        this.serviceImgSrc = require('../../../assets/images/doc/icon-customerservic@2x.png')
      }
    },
    docFeedBackDialog() {
      this.$refs.openDialog.show()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.backToTop {
  position: fixed;
  right: 0;
  bottom: 250px;
  box-shadow: @boxShadow;
  z-index: 100;
  li {
    width: 54px;
    display: flex;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 54px;
      height: 72px;
      background: #fff;
      // border-bottom: 1px solid #e2e8ff;
      img {
        width: 24px;
        // display: ;
        // color: @mainColor;
      }
      p {
        position: absolute;
        // padding: 0 15px;
        font-size: 14px;
        color: @hoversColor;
        box-shadow: @boxShadow;
        border-radius: 4px;
        text-align: center;
        height: 44px;
        line-height: 44px;
        background: #fff;
        width: 98px;
        left: -103px;
        font-weight: 500;
        display: none;
        &::after {
          position: absolute;
          top: 50%;
          right: -4px;
          transform: translateY(-50%);
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-left: 8px solid #fff;
          border-bottom: 10px solid transparent;
        }
        &.relate {
          width: 120px;
          left: -126px;
        }
      }
      .icon-desc {
        text-align: center;
        span {
          display: block;
          font-size: 14px;
          color: #1c1f22;
        }
      }
    }
    &:hover {
      a {
        // background: @mainColor;
        span {
          color: @mainColor;
        }
      }
      p {
        display: block;
      }
    }
  }
}
.docBox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 140px;
  box-shadow: @boxShadow;
  z-index: 100;
  width: 54px;
  height: 104px;
  background: @mainColor;
  border-radius: 4px 0px 0px 4px;
  color: @whiteBcgColor;
  div {
    text-align: center;
  }
  span {
    display: block;
    font-size: 14px;
  }
  &:hover {
    opacity: 0.9;
  }
}
</style>
